.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    background-color: var(--colorScheme-background);

    --shadowHeight: -0.5px;

    box-shadow: inset 0 var(--shadowHeight) 0 var(--colorScheme-dividerMinor);
}

.header.s {
    --shadowHeight: -1px;
}

.transparentHeader,
.transparentHeader::after {
    box-shadow: none;
    background-color: transparent;
}

.newYear {
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/new-year-header.png'),
        var(--colorScheme-background);
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.newYear.s {
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/new-year-header-small.png'),
        var(--colorScheme-background);
    background-repeat: no-repeat;
    background-size: auto 100%;
}
